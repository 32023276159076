import React, { useState } from "react";
import { Link } from "react-router-dom";
import cvFile from "../assets/CV.pdf";
import { Helmet } from "react-helmet";

const About = () => {
  const [flippedCard, setFlippedCard] = useState(null);

  const toggleFlip = (cardId) => {
    setFlippedCard(flippedCard === cardId ? null : cardId);
  };

  return (
    <>
    <Helmet>
        <title>Marie-Anne Roth - à propos</title>
        <meta
          name="description"
          content="En savoir plus sur qui je suis, et sur mon parcours de formation."
        />
      </Helmet>
    <div className="bg-gray-100 min-h-screen py-10 px-6">
      <div className="max-w-5xl mx-auto">
        <h1 className="text-4xl font-bold text-gray-800 text-center">À propos de moi</h1>
        <p className="mt-4 text-lg text-gray-600 text-center">
        Forte de  <span className="text-blue-500 font-semibold">4 ans d’expériences professionnelles en gestion de projets web et en marketing digital</span>, j’ai repris mes études à Epitech pour renforcer mes compétences en développement web et acquérir des compétences en programmation et en réseau.
        </p>
        <div className="mt-8 flex flex-wrap justify-center gap-4">
          <a
            href={cvFile}
            download="Marie-Anne-Roth-CV.pdf"
            className="px-6 py-3 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
          >
            Télécharger mon CV
          </a>
          <Link
            to="/contact"
            className="px-6 py-3 bg-gray-800 text-white rounded hover:bg-gray-900 transition"
          >
            Me contacter
          </Link>
        </div>

        <div className="mt-12">
          <h2 className="text-2xl font-bold text-gray-800">🎓 Parcours académique</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">

            <div
              className={`relative bg-white p-6 rounded-lg shadow-lg transition-transform transform ${
                flippedCard === "epitech" ? "rotate-y-180" : ""
              }`}
            >
              <div
                className={`${flippedCard === "epitech" ? "hidden" : "block"}`}
              >
                <h3 className="text-xl font-semibold text-blue-500">
                  EPITECH
                </h3>
                <p className="font-bold text-gray-800">Mastère of science | Bac + 5</p>
                <p className="text-gray-600 mt-2">
                  Parcours MSC PRO <span className="font-semibold">Expert en Management des Systèmes d'Information</span>.
                </p>
                <button
                  onClick={() => toggleFlip("epitech")}
                  className="mt-4 inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
                >
                  🔍 Découvrir
                </button>
              </div>
              <div
                className={`p-4 bg-blue-50 rounded-lg ${
                  flippedCard === "epitech" ? "block" : "hidden"
                }`}
              >
                <p className="text-gray-600">
                  Le parcours MSc Pro Expert en Management des Systèmes d'Information d'EPITECH forme des professionnels capables de piloter des projets technologiques, de concevoir des systèmes d'information performants et de gérer l'innovation numérique, avec une forte orientation vers le management, le développement technique et les enjeux stratégiques de l'entreprise.
                  <a
                    href="https://eparcours.eu/kit-dalternance-epitech-pre-msc-msc-pro-2024-2027-promotion-n230/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline ml-1"
                  >
                    En savoir plus
                  </a>
                  .
                </p>
                <button
                  onClick={() => toggleFlip("epitech")}
                  className="mt-4 inline-block bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400 transition"
                >
                  🔙 Retour
                </button>
              </div>
            </div>

            <div
              className={`relative bg-white p-6 rounded-lg shadow-lg transition-transform transform ${
                flippedCard === "epitechDigital" ? "rotate-y-180" : ""
              }`}
            >

              <div
                className={`${flippedCard === "epitechDigital" ? "hidden" : "block"}`}
              >
                <h3 className="text-xl font-semibold text-blue-500">
                  EPITECH Digital
                </h3>                
                <p className="font-bold text-gray-800">Bachelor | Bac + 3</p>
                <p className="text-gray-600 mt-2">
                  Bachelor <span className="font-semibold">Manager de Projets Web</span>, spécialité Web Marketing.
                </p>
                <button
                  onClick={() => toggleFlip("epitechDigital")}
                  className="mt-4 inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
                >
                  🔍 Découvrir
                </button>
              </div>

              <div
                className={`p-4 bg-blue-50 rounded-lg ${
                  flippedCard === "epitechDigital" ? "block" : "hidden"
                }`}
              >
                <p className="text-gray-600">
                  Le Bachelor spécialité Web Marketing de Sup'Internet, devenu Epitech Digital, formait des professionnels maîtrisant les stratégies digitales, la gestion de projets web et les outils de communication numérique pour concevoir et piloter des campagnes marketing innovantes.                  
                </p>
                <p>
                  <a
                    href="https://www.epitech-digital.fr/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline ml-1"
                  >En savoir plus
                  </a>
                </p>
                <button
                  onClick={() => toggleFlip("epitechDigital")}
                  className="mt-4 inline-block bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400 transition"
                >
                  🔙 Retour
                </button>
              </div>
            </div>
          </div>
        </div>        
      </div>
    </div>
    </>
  );
};

export default About;